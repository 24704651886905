.back {
	background-image: url(/src/img/background.jpg);
	background-size: cover; /* or contain depending on what you want */
  background-position: center center;
  background-repeat: no-repeat;
	background-repeat: no-repeat;
	height: 100vh;
	margin-top: -100px;
	z-index: 1;
	position: relative;
}

.back::after {
  -webkit-backdrop-filter: blur(0px) grayscale(0.6); /* Use for Safari 9+, Edge 17+ (not a mistake) and iOS Safari 9.2+ */
  backdrop-filter: blur(0px) grayscale(0.6); /* Supported in Chrome 76 */
  content: "";
  display: block;
  position: absolute;
  width: 100%; height: 100%;
  top: 0;
}

.back-filter {
  position: relative;
}

.title {
	padding-top: calc(0.35 * 100vh);
	display: flex;
	text-align: left;
}

.padding{
	position: relative;
	z-index: 99;
	text-align: center;
	height: calc(100vh - 72px);
	min-height: 500px;
}

.line {
	width: 100vw;
	position: absolute;
	top: max(100vh, 600px);
	left: 0;
}

.text {
	top: calc(50vh - 20%);
	width: 800px;
	font-size: 3rem;
	animation: fade-inout 6s;
	font-weight: 400;
	filter: brightness(110%);
	border-left: 4.5px solid #630330;
	padding-left: 1rem;
}

.text span {
	filter: brightness(100%);
}

div.arrow {
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: max(88vh, 528px);
  transform: rotate(135deg);
}

.report h4:hover {
	cursor: pointer;
	opacity: 40%;
}
  
div.arrow::before {
	content: '';
	width: 100%;
	height: 100%;
	border-width: .8vmin .8vmin 0 0;
	border-style: solid;
	border-color: var(--text-secondary);
	transition: .2s ease;
	display: block;
	transform-origin: 100% 0;
}
  
div.arrow:hover::after {
	transform: rotate(45deg);
	height: 120%;
}

div.arrow:hover::before {
	transform: scale(.8);
}

.performance {	
	padding-top: 2.5rem;
	width: 100%;
}

.stats h1, .doc h1 {
	filter: brightness(120%);
}

.flex {
	padding: 1.5rem 0 0.5rem 0;
	align-items: baseline;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
}

.unit {
	text-align: center;
	padding: 1rem 3rem;
	border-radius: 1.5rem;
	margin: 1rem 1.5rem;
}

.unit:hover {
	transform: scale(1.1);
	cursor: default;
}

.container {
	padding: 0 1rem 0 1rem;
}

.charts {
	height: 550px;
	align-self: center;
	border-radius: 1.2rem;
	box-sizing: border-box;
	width: 100%;
}

.year {
	color: rebeccapurple;
}

.doc h1 {
	font-weight: 400;
	margin-bottom: 2rem;
	-webkit-text-fill-color: var(--text-secondary);
}

.doc1 {
	padding: 6rem 0 6rem 0;
}

.doc1 h4:hover {
	opacity: 100%;
	cursor: default;
}

.report {
	padding: 1rem 0 1rem 0;
}

.report h4, .report h2 {
	font-weight: 325;
}

.myimg {
	color: var(--text-secondary);
}

@media (max-width: 750px) {
  .charts {
		height: 400px;
	}

	.text {
		font-size: 1.9rem;
	}
}

@media (max-width: 608px) {
  .text {
		font-size: 2rem;
		border-left: 3px solid #630330;
		padding-left: 0.3rem;
	}

	.stats h1 {
		font-size: 1.5rem;
	}

	.stats h2 {
		font-size: 1rem;
	}
}

@media (max-width: 525px) {
	.unit {
		margin: 0.5rem;
		width: 100%;
	}
}

@media (max-width: 500px) {

  .charts {
		height: 300px;
	}

	.unit {
		margin: 0.5rem;
	}

	.flex {
		padding: 1rem 0 0.3rem 0;
	}
}

@media (max-width: 400px) {
  .text {
		font-size: 1.7rem;
	}
}

@keyframes fade-inout {
	0%{ opacity: 0;}
	100%{ opacity: 1;}
}



