.center {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	align-self: center;
	margin-top: calc(0.10 * 100vh);
}

.title {
	margin-bottom: 2rem;
	font-weight: 400;
}

.block {
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	border-radius: 1.5rem;
	width: 400px;
	margin-bottom: 100px;
}

.row, .submit {
	display: flex;
	width: 100%;
	margin: 0.3rem;
	padding: 0.5rem;
	background-color: #FDF1E6;
	border: 2px solid #1E1E1E;
	border-radius: 0.5rem;
	font-size: 1rem;
	max-height: 3rem;
	align-items: center;
	color: #1E1E1E;
}

.row input {
	font-size: 1rem;
	border: none;
	background-color: #FDF1E6;
	width: 100%;
	padding: 0.5rem;
	offset: none;
	outline: none;
}

.submit {
	display: flex;
	justify-content: center;
	width: 100%;
	border: 2px solid #1E1E1E;
	background-color: #FDF1E6;
	height: 3rem;
	font-weight: 475;
}

.submit:hover {
	opacity: 80%;
	background-color: #630330;
	color: #FDF1E6;
}

.icon {
	padding: 0 5px 0 5px;
	border: none;
	background-color: #FDF1E6;
	color: #630330;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
	transition: background-color 600000s 0s, color 600000s 0s;
	offset: none;
	font-size: 1rem;
}

.error {
	color: red;
	margin: 0.5rem 0 0 0;
}

@media (max-width: 400px) {
  .title {
		font-size: 1.7rem;
	}
}