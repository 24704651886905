.bg {
	display: flex;
	height: 200px;
	width: 100%;
	background-color: var(--background);
	color: var(--text-secondary);
	background-blend-mode: darken, luminosity;
	justify-content: center;
	filter: brightness(98%);
}

.pad {
	width: 100%;
	max-width: 1700px;
	display: flex;
	justify-content: space-around;
}

.left, .right {
	display: flex;
	flex-direction: column;
	justify-content: center;
	line-height: 1rem;
}

.pad h5, .pad h4 {
	line-height: 2rem;
	font-weight: 400;
}

.pad h3 {
	font-weight: 400;
}

@media screen and (max-width: 450px) {
  h3 {
		font-size: 1rem;
	}
}
