* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* #000000*/

:root {
  --background: #1E1E1E;
  --text-secondary: #E0E0E0;
  --shade: #d7eafa; 
}

body {
  z-index: -10;
  background-color: #1E1E1E;
  font-family: "Plantin MT Light", sans-serif;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
  letter-spacing: 0.45px;
  font-style: normal;
  text-rendering: optimizeLegibility;
   -webkit-font-smoothing: subpixel-antialiased !important;
   -webkit-font-smoothing: antialiased !important;
   -moz-osx-font-smoothing: grayscale !important;
}

.App {
  background-color: var(--background);
}

ul {
  list-style: none;
}

a, li {
  text-decoration: none;
  color: var(--text-secondary);
}

a:hover, li:hover {
  cursor: pointer;
}

.content {
  z-index: 5;
  min-height: calc(100vh - 100px);
  box-sizing: border-box;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 42px;
  color: var(--text-secondary);
}

button:hover {
  cursor: pointer;
}


@keyframes BackgroundAnimation {
  0% {
      background-position: 52% 0%;
  }
  50% {
      background-position: 49% 100%;
  }
  100% {
      background-position: 52% 0%;
  }
}

@media (max-width: 610px) {
  .content {
    padding: 0 24px;
  }
}