.test {
  display: flex;
	width: 100%;
	height: 350px;
	box-sizing: border-box;
	flex-wrap: wrap;
	font-size: 1.5rem;
	font-weight: 600;
}

.svgtest {
	flex: 1;
	height: 100%;
	box-sizing: border-box;
}

@media screen and (max-width: 450px) {
  .test {
		font-size: 1rem;
		height: 275px;
		font-weight: 300;
	}
}


