.navbar, .navbar_white {
	width: 100%;
	height: 100px;
	display: flex;
	justify-content: space-between;
	vertical-align: center;
	align-items: center;
	padding: 0rem 2rem;
	z-index: 99999;
	position: relative;
}

.logo {
	font-size: 1.8rem;
}

.logo li {
	height: 30px;
}

ul, a {
	list-style: none;
}

a:hover, li:hover {
	opacity: 80%;
	cursor: pointer;
}

.nav_menu, .nav_menu_active {
	display: flex;
	list-style: none;
	align-items: center;
	font-size: 1.15rem;
}

.nav_item {
	list-style: none;
	padding: 1rem;
	cursor: pointer;
	font-weight: 400;
}

.hamburger {
	display: none;
}

@media screen and (max-width:1140px) {
	.nav_menu {
		position: fixed;
		top: 100px;
		left: -1000%;
		flex-direction: column;
		justify-content: center;
		background-color: var(--background);
		text-align: center;
		width: 100%;
		z-index: 9999;
	}

	.nav_menu_active {
		position: fixed;
		top: 100px; 
		left: 0;
		flex-direction: column;
		justify-content: start;
		align-items: center;
		background-color: var(--background);
		text-align: center;
		width: 100%;
		height: 100%;
		z-index: 9999;
	}

	.navbar_white {
		position: fixed;
		top: 0;
		width: 100%;
		height: 100px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: var(--background);
		z-index: 9999;
	}

	.nav_item {
		margin: 1.5rem;
		font-size: 1.3rem;
		justify-content: center;
	}

	.hamburger {
		display: flex;
		cursor: pointer;
	}
}
