.message {
	max-width: 100%;
	justify-content: center;
	display: flex;
	flex-wrap: wrap;
	height: calc(100vh - 72px);
	align-items: center;
}

.pic {
	display: flex;
	flex-direction: column;
	align-items: center;
}

img {
	max-width: 100%;
  max-height: 100%;
	object-fit: contain;
}

.music {
	display: flex;
	padding: 0 4.5rem 0 4.5rem;
	max-width: 100%;
	box-sizing: border-box;
}

.icon {
	margin: 0 4rem 0 4rem;
	animation: bounce 1.5s infinite;
}

.icon2 {
	margin: 0 4rem 0 4rem;
	animation: bounce2 1.5s infinite;
}

@keyframes bounce {
	25% {
		transform: rotate(20deg);
	}
	50% {
		transform: translateY(-100%);
	}
	75% {
		transform: rotate(-20deg);
	}
}

@keyframes bounce2 {
	25% {
		transform: rotate(-15deg);
	}
	50% {
		transform: translateY(100%);
	}
	75% {
		transform: rotate(30deg);
	}
}

@media screen and (max-width: 1050px) {
	.pic {
		width: 100%;
	}
}

@media screen and (max-width: 850px) {
	.icon, .icon2 {
		margin: 0 3rem 0 3rem;
	}
}

@media screen and (max-width: 700px) {
	.icon, .icon2 {
		margin: 0 2rem 0 2rem;
	}
}

@media screen and (max-width: 520px) {
	.icon, .icon2 {
		margin: 0 1.5rem 0 1.5rem;
	}

	.music {
		padding: 0;
	}
}