.message h1 {
  font-family: 'Sacramento', cursive;
  font-style: normal;
  font-weight: 400;
  color: #FDF1E6;
  
  font-size: 3rem;
  text-align: left;
}

.message {
  display: flex;
  margin: 2.5rem 0 0.5rem 0;
}

.flexbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 4.8rem 0 4.8rem 0;
  height: 350px;
}

.boxl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 100%;
  border-radius: 1.2rem;
  box-sizing: border-box;
  position: relative;
  flex-grow: 1;
}

.boxr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 325px;
  border-radius: 1.2rem;
  box-sizing: border-box;
  position: relative;
  height: 100%;
}

.stats {
  padding: 2rem 0 0 0;
}

.flexing {
  display: flex;
  justify-content: left;
}

.stats h3 {
  margin: 0 0 5px 0;
  font-size: 2rem;
}

.return {
  font-size: 1rem;
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
}

.return h4 {
  font-size: 1rem;
  padding-right: 0.3rem;
  font-weight: 500;
  display: flex;
  filter: brightness(115%);
}

.stats h5 {
  font-weight: 500;
  font-size: 0.75rem;
}

@media screen and (max-width: 926px) {
  .border {
    width: 100%;
    border: 3px solid aliceblue;
    border-radius: 1.5rem;
  }
  
  .boxr {
    width: 320px;
  }
}

@media screen and (max-width: 450px) {
  .boxl {
    width: 100%;
    height: 400px;
  }

  .boxr {
    width: 100%;
  }

  .message h1 {
    font-size: 2rem;
  }

  .message {
    margin: 0.5rem 0 0.25rem 0;
  }

  .stats h3 {
    font-size: 1.6rem;
  }

  .return h4 {
    font-size: 0.8rem;
  }

  .stats h5 {
    font-size: 0.6rem;
  }

  .flexbox {
    margin: 2.5rem 0 2.5rem 0;
    height: 275px;
  }

  .boxl {
    box-sizing: border-box;
    height: 275px;
  }
}