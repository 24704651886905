.tickerview {
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  position: relative;
  color: var(--text-secondary);
}

.flex {
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: 400;
}

.numberplaceholder {
  visibility: hidden;
}

.tickercolumncontainer {
  position: relative;
}

.tickercolumn {
  position: absolute;
  height: 1000%;
  bottom: 0;
}

.tickerdigit {
  height: 10%;
}

.ticker-column.increase {
  animation: pulseGreen 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

.ticker-column.decrease {
  animation: pulseRed 500ms cubic-bezier(0.4, 0, 0.6, 1) 1;
}

@keyframes pulseGreen {
  0%,
  100% {
    color: white;
  }

  50% {
    color: green;
  }
}

@keyframes pulseRed {
  0%,
  100% {
    color: white;
  }

  50% {
    color: red
  }
}
